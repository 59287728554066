<div fxLayout=row>
  <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedTab" (selectedTabChange)="onTabSelected($event)">
    <mat-tab label="{{ 'Installed Applications' | translate}}"
          ix-auto ix-auto-type="tab" ix-auto-identifier="charts">
      <app-charts (updateTab)="updateTab($event)"></app-charts>
    </mat-tab>
    <mat-tab label="{{ 'Available Applications' | translate}}"
          ix-auto ix-auto-type="tab" ix-auto-identifier="catalog">
      <app-catalog (updateTab)="updateTab($event)"></app-catalog>
    </mat-tab>
    <mat-tab label="{{ 'Manage Catalogs' | translate}}"
          ix-auto ix-auto-type="tab" ix-auto-identifier="manage-catalog">
      <app-manage-catalogs></app-manage-catalogs>
    </mat-tab>
    <mat-tab label="{{ 'Manage Docker Images' | translate}}"
          ix-auto ix-auto-type="tab" ix-auto-identifier="manage-docker-images">
      <app-docker-images></app-docker-images>
    </mat-tab>
  </mat-tab-group>
</div>
